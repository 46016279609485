var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "b-navbar",
        { attrs: { type: "", "fixed-top": "" } },
        [
          _c(
            "template",
            { slot: "brand" },
            [
              _c(
                "b-navbar-item",
                { attrs: { tag: "router-link", to: { path: "/" } } },
                [
                  _c("img", {
                    staticClass: "neuro-logo",
                    attrs: { src: "images/favicon.svg", alt: "Порфирьевич" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "neuro-logo-text primary" }, [
                    _vm._v("Порфирьевич"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "end" },
            [
              _c(
                "b-navbar-item",
                { attrs: { tag: "router-link", to: "/gallery" } },
                [_c("strong", [_vm._v("Галерея")])]
              ),
              _vm._v(" "),
              _c(
                "b-navbar-item",
                { attrs: { tag: "router-link", to: "/about" } },
                [_vm._v("О проекте")]
              ),
              _vm._v(" "),
              _vm.user
                ? _c("b-navbar-item", { on: { click: _vm.logout } }, [
                    _vm._v("Выход"),
                  ])
                : _c(
                    "b-dropdown",
                    {
                      attrs: {
                        position: "is-bottom-left",
                        "aria-role": "menu",
                        "trap-focus": "",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "navbar-item",
                          attrs: { slot: "trigger", role: "button" },
                          slot: "trigger",
                        },
                        [
                          _c("span", [_vm._v("Вход")]),
                          _vm._v(" "),
                          _c("b-icon", { attrs: { icon: "menu-down" } }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "aria-role": "menu-item",
                            focusable: false,
                            custom: "",
                            paddingless: "",
                          },
                        },
                        [
                          _c("div", { staticClass: "custom-menu-content" }, [
                            _c("div", { staticClass: "custom-menu-item" }, [
                              _c("p", [_vm._v("Войти через:")]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "custom-menu-item" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { "icon-left": "google" },
                                    on: { click: _vm.login },
                                  },
                                  [_vm._v("Google")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("section", { staticClass: "section" }, [
        _c(
          "div",
          { staticClass: "columns is-mobile" },
          [
            !_vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "column is-full" },
                  [
                    _c("router-view", {
                      key: _vm.pageKey,
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var Component = ref.Component
                              return [_c(Component, { tag: "component" })]
                            },
                          },
                        ],
                        null,
                        false,
                        4222730052
                      ),
                    }),
                  ],
                  1
                )
              : _c("b-loading", { attrs: { "is-full-page": false } }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("footer", { staticClass: "footer" }, [
        _c("div", { staticClass: "content has-text-centered" }, [
          _c("p", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://t.me/+x3FR1E6PIbVjN2I6",
                  target: "_blank",
                  title: "porfirevich_ru",
                },
              },
              [_c("b-icon", { attrs: { size: "is-large", icon: "telegram" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://github.com/mgrankin/ru_transformers",
                  target: "_blank",
                },
              },
              [_c("b-icon", { attrs: { size: "is-large", icon: "github" } })],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content has-text-centered footer-support" }, [
          _c("h4", { staticClass: "footer-title" }, [
            _vm._v("\n        Поддержите "),
            _c("strong", { style: { color: _vm.color } }, [
              _vm._v("Порфирьевича"),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-donations columns" }, [
      _c("div", { staticClass: "footer-donation column" }, [
        _c("div", { staticClass: "footer-donation-action" }, [
          _c(
            "a",
            {
              staticClass: "button boosty-button",
              attrs: {
                href: "https://boosty.to/porfirevich",
                target: "_blank",
              },
            },
            [
              _c("img", {
                attrs: { src: "/images/boosty_white.svg", alt: "Boosty" },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "patreon-button",
              attrs: {
                href: "https://www.patreon.com/Porfirevich",
                target: "_blank",
              },
            },
            [
              _c("img", {
                attrs: {
                  src: "https://bulma.io/assets/images/become-a-patron.png",
                  srcset:
                    "https://bulma.io/assets/images/become-a-patron.png" +
                    " 1x, " +
                    "https://bulma.io/assets/images/become-a-patron@2x.png" +
                    " 2x, " +
                    "https://bulma.io/assets/images/become-a-patron@3x.png" +
                    " 3x",
                  alt: "Become a Patron",
                  width: "148",
                  height: "36",
                },
              }),
            ]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }